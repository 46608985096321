import React from 'react'

// generic images
import ContactlessIcon from '../../contactless.png'
import SummitImageWhite from '../../summit-white.png'

// PDX_2023
import GFX1 from './gfx-1.png'
import GFX2 from './tokyo-2024.png'
import SHARE_BG from './share-bg.png'

function AvvySvg(props) {
  return (
    <>
      <svg height={props.height} width={props.width} viewBox="0 0 409 105" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.7324 54.1444L3.68426 71.7523C-9.96181 35.6561 16.0097 0 53.4267 0C89.3468 0 113.293 38.7374 100.528 70.4317L84.2405 53.7042L91.7239 45.7806L86.4415 40.9384L78.9582 48.862L53.4267 22.8903L26.5746 49.3022L19.0912 40.9384L13.8088 45.7806L21.7324 54.1444Z" fill="black"/>
      <path d="M53.4267 33.0149L31.4166 54.5846L38.0195 62.068L52.1059 47.5414L66.6324 62.068L74.1158 54.1444L53.4267 33.0149Z" fill="black"/>
      <path d="M52.1059 57.2258L42.8617 66.9101L52.1059 77.4749L62.2304 66.9101L52.1059 57.2258Z" fill="black"/>
      <path d="M78.9582 58.9866L55.6275 84.0779V104.327C76.757 104.327 92.3107 86.1321 97.4463 77.0347L78.9582 58.9866Z" fill="black"/>
      <path d="M26.5743 59.4268L7.20557 78.7955C18.4746 98.8685 39.4868 104.18 48.5842 104.327V84.0779L26.5743 59.4268Z" fill="black"/>
      <path d="M145.61 76L165.252 32.385H180.319L199.961 76H189.347L185.199 66.789H160.372L156.224 76H145.61ZM163.91 58.859H181.661L173.182 40.01H172.389L163.91 58.859ZM230.615 76L210.973 32.385H221.587L237.752 68.375H238.545L254.71 32.385H265.324L245.682 76H230.615ZM301.413 76L281.771 32.385H292.385L308.55 68.375H309.343L325.508 32.385H336.122L316.48 76H301.413ZM375.688 76V60.445L352.569 32.385H364.647L380.568 52.149L396.428 32.385H408.506L385.509 60.384V76H375.688Z" fill="black"/>
      </svg>
    </>
  )
}

function Badge(props) {
  let fontSize = 'text-4xl'
  let len = props.name.length
  if (len >= 21) {
    fontSize = 'text-xl'
  } else if (len >= 18) {
    fontSize = 'text-2xl'
  } else if (len >= 13) {
    fontSize = 'text-3xl'
  }

  return (
    <div ref={props.badgeRef} className='z-0 relative w-full max-w-sm m-auto border border-black shadow-lg rounded-xl p-4 relative overflow-hidden flex flex-col' style={{ aspectRatio: '0.6296296296296297', width: '384px', backgroundColor: 'white' }}>
      <div className='relative z-10'>
        <div className='flex justify-between items-center mb-4'>
          <div className='h-6'>
            <AvvySvg width="auto" height="25" />
          </div>
          <div className='text-xs font-bold' ref={props.linkRef}>
            {'avvy.domains'}
          </div>
        </div>
        <div className='top-0 left-0 right-0 m-auto absolute bg-gray-300' style={{ width: '100px', height: '24px', borderRadius: '20px', }}></div>
      </div>
      <div className='relative z-20 flex-shrink-0 aspect-square w-full bg-gray-100 bg-center bg-cover flex items-center justify-center cursor-pointer' onClick={props.upload}>
        <div className='relative z-10 bg-gray-100 bg-center bg-cover items-center justify-center flex cursor-pointer w-full h-full border-2 border-black' style={{ borderRadius: '20px 20px 0 0', backgroundImage: `url(${props.avatar})` }}>{props.renderImageSection()}</div>
        <div className='absolute top-0 h-full w-full bg-white z-0'></div>
      </div>
      <div className={`bg-white relative z-10 font-bold text-center ${fontSize} h-full flex items-center justify-center border-0 border-t-4 border-white bg-black text-white`} style={{ borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', }}>
        <div ref={props.nameRef} style={{ paddingLeft: '10px', paddingRight: '10px', width: '100%', overflowWrap: 'anywhere' }}>
          {props.name.replace('.', '\u200B.')}
        </div>
      </div>
      <div className='relative z-10 w-full flex-shrink-0 bottom-0 left-0 right-0 m-auto text-center flex justify-between items-center' style={{ paddingTop: '15px' }}>
        <div className='invisible'>
          <img src={SummitImageWhite} style={{ height: 30 }} />
        </div>
        <div className='invisible'>
          <img src={ContactlessIcon} style={{ height: 25 }} />
        </div>
      </div>
      <div className='absolute bottom-0 left-0 w-full z-0 flex justify-between mb-3'>
        <div className='w-1/2 flex justify-start pl-4'>
          <img src={GFX1} style={{ height: 40 }} />
        </div>
        <div className='w-1/2 flex justify-end pr-4'>
          <img src={GFX2} style={{ height: 40 }} />
        </div>
      </div>
    </div>
  )
}

export default {
  EVENT_NAME: 'Avalanche JP Tokyo 2024',
  BADGE_COMPONENT: Badge,
  SHARE_BG,
}
