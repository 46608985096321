import { useState, useEffect, useRef } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AVVY from '@avvy/client'
import { BookmarkIcon, Bars3Icon, XMarkIcon, PlusIcon, XCircleIcon, CheckCircleIcon, CheckIcon, ArrowUpIcon, ArrowDownIcon, TrashIcon } from '@heroicons/react/24/solid'
import { BookmarkIcon as BookmarkOutlineIcon } from '@heroicons/react/24/outline'
import './App.css';
import qrcode from 'qrcode'
import fetch from 'node-fetch'
import html2canvas from 'html2canvas'
import QrScanner from 'qr-scanner'
import '@rainbow-me/rainbowkit/styles.css'
import { utils as ensAvatarUtils } from '@ensdomains/ens-avatar'
import {
  lightTheme,
  getDefaultWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { CoreWalletConnector } from '@vaporfi/core-wagmi-connector';

import IPhoneImage from './iphone.png'
import SummitImage from './summit.png'
import SummitImageWhite from './summit-white.png'
import LogoNoText from './logo-no-text.png'
import ContactlessIcon from './contactless.png'
import ShareBG from './sharebg.png'
import Beep from './beep.wav'

// PDX_2023
import PDX_2023_LOGO from './events/pdx-2023/logo.png'
import PDX_2023_GFX1 from './events/pdx-2023/gfx-1.png'
import PDX_2023_GFX2 from './events/pdx-2023/gfx-2.png'
import PDX_2023_SHAREBG from './events/pdx-2023/share-bg.png'

// AVALANCHE_JP
import AVALANCHE_JP_TOKYO_2023 from './events/avalanche-jp-tokyo-2023/index.js'
import AVALANCHE_JP_TOKYO_2024 from './events/avalanche-jp-tokyo-2024/index.js'
import AVALANCHE_CANADA_MONTREAL_2024 from './events/avalanche-canada-montreal-2024/index.js'


// profile
import { QueryClient, QueryClientProvider, QueryCache } from 'react-query'
import { useAccount, useConnect, useDisconnect, useContract } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { getProvider } from '@wagmi/core'

// overview
import { WagmiConfig, createClient, configureChains, useSigner, useProvider } from 'wagmi'
import { ethers } from 'ethers'
import { avalanche, avalancheFuji } from 'wagmi/chains'

import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal, Web3Button } from "@web3modal/react";

const ENV = {
  'EVENT_NAME': 'AVAX PDX 2023',
  'EVENT_CITY': 'Portland',
}

const EVENTS = {
  //'avalanche-jp-tokyo-2023': AVALANCHE_JP_TOKYO_2023,
  'avalanche-jp-tokyo-2024': AVALANCHE_JP_TOKYO_2024,
  'avalanche-canada-montreal-2024': AVALANCHE_CANADA_MONTREAL_2024,
}

const API_BASE_URL = 'https://avvy.domains'
const DELIMITER = '\x03'

const projectId = '12e3bb0c6341f5565a50bfb47891c886'
const chains = [avalanche]

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: () => {
      alert('ok')
    },
  }),
})


const configuredChains = configureChains(chains, [
  w3mProvider({ projectId }),
]);
const provider = new AVVY.providers.ethersProvider(configuredChains.provider)

const { connectors } = getDefaultWallets({
  appName: 'My RainbowKit App',
  projectId: 'YOUR_PROJECT_ID',
  chains
});

const wagmiClient = createClient({
  autoConnect: true,
  provider,
  connectors: [
    ...connectors(),

    new CoreWalletConnector({ chains, options: {} }),
    new InjectedConnector({ chains }),
  ],
})

const ethereumClient = new EthereumClient(wagmiClient, chains)
const ethersProvider = new ethers.providers.JsonRpcProvider('https://api.avax.network/ext/bc/C/rpc')

const avvy = new AVVY(ethersProvider)


function Loader(props) {
  return (
    <div>
      <span class="loader" {...props}></span>
    </div>
  )
}

function AvvySvg(props) {
  return (
    <>
      <svg height={props.height} width={props.width} viewBox="0 0 409 105" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.7324 54.1444L3.68426 71.7523C-9.96181 35.6561 16.0097 0 53.4267 0C89.3468 0 113.293 38.7374 100.528 70.4317L84.2405 53.7042L91.7239 45.7806L86.4415 40.9384L78.9582 48.862L53.4267 22.8903L26.5746 49.3022L19.0912 40.9384L13.8088 45.7806L21.7324 54.1444Z" fill="black"/>
      <path d="M53.4267 33.0149L31.4166 54.5846L38.0195 62.068L52.1059 47.5414L66.6324 62.068L74.1158 54.1444L53.4267 33.0149Z" fill="black"/>
      <path d="M52.1059 57.2258L42.8617 66.9101L52.1059 77.4749L62.2304 66.9101L52.1059 57.2258Z" fill="black"/>
      <path d="M78.9582 58.9866L55.6275 84.0779V104.327C76.757 104.327 92.3107 86.1321 97.4463 77.0347L78.9582 58.9866Z" fill="black"/>
      <path d="M26.5743 59.4268L7.20557 78.7955C18.4746 98.8685 39.4868 104.18 48.5842 104.327V84.0779L26.5743 59.4268Z" fill="black"/>
      <path d="M145.61 76L165.252 32.385H180.319L199.961 76H189.347L185.199 66.789H160.372L156.224 76H145.61ZM163.91 58.859H181.661L173.182 40.01H172.389L163.91 58.859ZM230.615 76L210.973 32.385H221.587L237.752 68.375H238.545L254.71 32.385H265.324L245.682 76H230.615ZM301.413 76L281.771 32.385H292.385L308.55 68.375H309.343L325.508 32.385H336.122L316.48 76H301.413ZM375.688 76V60.445L352.569 32.385H364.647L380.568 52.149L396.428 32.385H408.506L385.509 60.384V76H375.688Z" fill="black"/>
      </svg>
    </>
  )
}

function AvvyButton({ displayName, address, openAccountModal }) {
  const [avvyName, setAvvyName] = useState(null)

  useEffect(() => {
    let ignore = false
    const run = async () => {
      if (!ignore) {
        try {
          const hash = await avvy.reverse(AVVY.RECORDS.EVM, address)
          const name = await hash.lookup()
          setAvvyName(name.name)
        } catch (err) {
        }
      }
    }
    run()
    return () => {
      ignore = true 
    }
  })

  return (
    <button className='py-2 px-4 rounded-xl bg-black text-white' onClick={openAccountModal} type="button">
      {avvyName || displayName}
    </button>
  )
}

function AvvyConnectButton(props) {
	return (
	  <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== 'loading';
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus ||
            authenticationStatus === 'authenticated');

        return (
          <div
            {...(!ready && {
              'aria-hidden': true,
              'style': {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <button className='py-2 px-4 rounded-xl bg-black text-white' onClick={openConnectModal} type="button">
                    Connect Wallet
                  </button>
                );
              }

              if (chain.unsupported) {
                return (
                  <button className='py-2 px-4 rounded-xl bg-black text-white' onClick={openChainModal} type="button">
                    Wrong network
                  </button>
                );
              }

              return (
                <div style={{ display: 'flex', gap: 12 }}>
                  <AvvyButton displayName={account.displayName} address={account.address} openAccountModal={openAccountModal} />
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
	)
}

function StepOne(props) {
  const address = props.address
  const [ domainIds, setDomainIds ] = useState(null)
  const [ domainBalance, setDomainBalance ] = useState(null)
  const [ domainNames, setDomainNames ] = useState(null)

  useEffect(() => {
    let ignore = false
    const run = async () => {
      const contracts = await avvy.contracts
      const balance = await contracts.Domain.balanceOf(address)
      const domains = await avvy.wallet(address).domains()
      const domainNames = {}
      const plaintexts = await avvy.batch(domains).lookup()
      for (let i = 0; i < domains.length; i += 1) {
        if (plaintexts[i]) {
          domainNames[domains[i].toString()] = plaintexts[i]
        }
      }
      if (!ignore) {
        setDomainBalance(domains.length)
        setDomainIds(domains.map(d => d.toString()))
        setDomainNames(domainNames)
      }
    }
    run()
    return () => {
      ignore  = true
    }
  }, [address])

  let revealTimeout
  function revealName(name) {
    if (revealTimeout) clearTimeout(revealTimeout)
    revealTimeout = setTimeout(async function () {
      const hash = await avvy.utils.nameHash(name)
      for (let i = 0; i < domainIds.length; i += 1) {
        if (hash.toString() === domainIds[i].toString()) {
          setDomainNames((domainNames) => {
            return {
              ...domainNames,
              [hash.toString()]: name
            }
          })
        }
      }
    }, 300)
  }

  const names = domainIds ? domainIds.filter((id) => domainNames[id.toString()]).map((id) => domainNames[id.toString()]).sort() : []

  return (
    <div className='max-w-md m-auto w-full'>
      <h1 className='font-bold text-4xl mb-4'>Choose your domain</h1>
      {domainIds === null ? (
        <div className='mt-8'>
          <Loader style={{ color: '#555' }} />
        </div>
      ) : domainIds.length === 0 ? (
        <div>
          <div>{"You don't have any domains.."}</div>
          <a className='block py-2 px-4 text-white rounded-xl font-bold mt-4 bg-black' href="https://app.avvy.domains">Register a domain</a>
        </div>
      ) : (
        <div>
          You have {domainBalance} {domainBalance === 1 ? 'domain' : 'domains'}. Which one do you want to set up?
          <div className='mt-4'>
            {names.map((name, index) => {
              return (
                <div key={index} className='p-4 border border-black rounded my-2 cursor-pointer break-all' onClick={() => props.completeStepOne(name)}>
                  {name}
                </div>
              )
            })}
          </div>
          {domainIds.length > Object.keys(domainNames).length ? (
            <div className='mt-8 text-gray-400'>
              <div>{"You have some Enhanced Privacy domain names which are currently hidden. If you want to configure one of them, type in the name below."}</div>
              <input type="text" className='w-full border-gray-500 border p-2 rounded mt-4' placeholder='Reveal Enhanced Privacy domains' onChange={(e) => revealName(e.target.value)} />
            </div>
          ) : null}
        </div>
      )}
    </div>
  )
}

function StepTwo(props) {
  const [ hasResolvedDomain, setHasResolvedDomain ] = useState(false)
  const [ avatar, setAvatar ] = useState(null)
  const [ avatarURL, setAvatarURL ] = useState(null)
  const [ avatarRecord, setAvatarRecord ] = useState(null)
  const [ avatarHasChanged, setAvatarHasChanged ] = useState(false)
  const uploadRef = useRef(null)
  const badgeRef = useRef(null)
  const linkRef = useRef(null)
  const nameRef = useRef(null)

  useEffect(() => {
    let ignore = false
    const run = async () => {
      if (hasResolvedDomain || ignore) return
      try {
        const urlRaw = await avvy.name(props.name).resolve(avvy.RECORDS.AVATAR)
        let url = ensAvatarUtils.resolveURI(urlRaw, {
          ipfs: 'https://w3s.link'
        }).uri
        if (url) {
          const data = await fetch(url)
          const blob = await data.blob()
          const avatar = await new Promise((resolve) => {
            const reader = new FileReader()
            reader.readAsDataURL(blob)
            reader.onloadend = () => {
              const base64data = reader.result
              resolve(base64data)
            }
          })
          setAvatarRecord(urlRaw)
          setAvatarURL(url)
          setAvatar(avatar)
        }
      } catch (err) {
      }
      setHasResolvedDomain(true)
    }
    run()
    return () => {
      ignore = true
    }
  })

  const upload = () => {
    const onChange = (e) => {
      uploadRef.current.removeEventListener('change', onChange)
      const file = e.target.files[0]
      if (!file) return
      if (file.size / 1024 / 1024 > 2) {
        return toast('Image must be smaller than 2 megabytes')
      }
      const allowedTypes = [
        'image/png',
        'image/jpeg',
        'image/gif', 
      ]
      if (allowedTypes.indexOf(file.type) === -1) {
        return toast('Invalid file. JPG, PNG or GIF only!')
      }

      const reader = new FileReader()
      reader.onload = (e) => {
        setAvatar(e.target.result)
        setAvatarURL(e.target.result)
	setAvatarHasChanged(true)
      }

      reader.readAsDataURL(file)
    }
    uploadRef.current.addEventListener('change', onChange)
    uploadRef.current.click()
  }

  const renderImageSection = () => {
    if (!hasResolvedDomain) return <Loader />
    if (!avatar) return (
      <div>Click to set your avatar</div>
    )
    return null
  }

  const badgeImage = async () => {
    nameRef.current.style.transform = 'translateY(-50%)'
    linkRef.current.style.transform = 'translateY(-50%)'
    badgeRef.current.classList.remove('border-8', 'border', 'shadow-lg', 'rounded-xl')
    const canvas = await html2canvas(badgeRef.current)
    return canvas.toDataURL()
  }

  const continueSteps = async () => {
    if (avatar) {
      const badge = await badgeImage()
      props.completeStepTwo(avatarHasChanged, avatar, avatarRecord, avatarURL, badge)
    } else {
      toast('Set your avatar to continue!')
    }
  }

  let fontSize = 'text-4xl'
  let len = props.name.length
  if (len >= 21) {
    fontSize = 'text-xl'
  } else if (len >= 18) {
    fontSize = 'text-2xl'
  } else if (len >= 13) {
    fontSize = 'text-3xl'
  }

  return (
    <div>
      <h1 className='font-bold text-4xl mb-4'>Design your badge</h1>
      <div className='mb-12'>Here is what your badge will look like 👇️</div>
      <div className='overflow-x-auto'>

        {/* AVALANCHE SUMMIT II 2023 
        <div ref={badgeRef} className='z-0 relative w-full max-w-sm m-auto border-8 border-gray-300 rounded p-4 relative overflow-hidden flex flex-col' style={{ aspectRatio: '0.6296296296296297', width: '384px' }}>
          <div className='relative z-10'>
            <div className='flex justify-between items-center mb-4'>
              <div className='h-6'>
                <AvvySvg width="auto" height="25" />
              </div>
              <div className='text-xs font-bold' ref={linkRef}>
                {'avvy.domains'}
              </div>
            </div>
            <div className='top-0 left-0 right-0 m-auto absolute bg-gray-300' style={{ width: '100px', height: '24px', borderRadius: '20px', }}></div>
          </div>
          <div className='relative z-20 flex-shrink-0 aspect-square w-full bg-gray-100 bg-center bg-cover flex items-center justify-center cursor-pointer' onClick={upload}>
            <div className='relative z-10 bg-gray-100 bg-center bg-cover items-center justify-center flex cursor-pointer w-full h-full' style={{ borderRadius: '20px', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', backgroundImage: `url(${avatar})` }}>{renderImageSection()}</div>
            <div className='absolute top-0 h-full w-full bg-white z-0'></div>
          </div>
          <div className={`bg-white relative z-10 font-bold text-center ${fontSize} h-full flex items-center justify-center`} style={{ borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', }}>
            <div ref={nameRef} style={{ paddingLeft: '10px', paddingRight: '10px', width: '100%', overflowWrap: 'anywhere' }}>
              {props.name.replace('.', '\u200B.')}
            </div>
          </div>
          <div className='relative z-10 w-full flex-shrink-0 bottom-0 left-0 right-0 m-auto text-center flex justify-between items-center' style={{ paddingTop: '15px' }}>
            <div>
              <img src={SummitImageWhite} style={{ height: 30 }} />
            </div>
            <div>
              <img src={ContactlessIcon} style={{ height: 25 }} />
            </div>
          </div>
          <div className='absolute bottom-0 left-0 w-full z-0'>
            <svg width="100%" height="auto" viewBox="0 0 374 381" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M373 132L0.5 1V380H373V132Z" fill="black" stroke="black"/>
            </svg>
          </div>
        </div>
        */}

        {/*
        <div ref={badgeRef} className='z-0 relative w-full max-w-sm m-auto border border-black shadow-lg rounded-xl p-4 relative overflow-hidden flex flex-col' style={{ aspectRatio: '0.6296296296296297', width: '384px', backgroundColor: 'white' }}>
          <div className='relative z-10'>
            <div className='flex justify-between items-center mb-4'>
              <div className='h-6'>
                <AvvySvg width="auto" height="25" />
              </div>
              <div className='text-xs font-bold' ref={linkRef}>
                {'avvy.domains'}
              </div>
            </div>
            <div className='top-0 left-0 right-0 m-auto absolute bg-gray-300' style={{ width: '100px', height: '24px', borderRadius: '20px', }}></div>
          </div>
          <div className='relative z-20 flex-shrink-0 aspect-square w-full bg-gray-100 bg-center bg-cover flex items-center justify-center cursor-pointer' onClick={upload}>
            <div className='relative z-10 bg-gray-100 bg-center bg-cover items-center justify-center flex cursor-pointer w-full h-full border-2 border-black' style={{ borderRadius: '20px 20px 0 0', backgroundImage: `url(${avatar})` }}>{renderImageSection()}</div>
            <div className='absolute top-0 h-full w-full bg-white z-0'></div>
          </div>
          <div className={`bg-white relative z-10 font-bold text-center ${fontSize} h-full flex items-center justify-center border-2 border-black border-t-0`} style={{ borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', }}>
            <div ref={nameRef} style={{ paddingLeft: '10px', paddingRight: '10px', width: '100%', overflowWrap: 'anywhere' }}>
              {props.name.replace('.', '\u200B.')}
            </div>
          </div>
          <div className='relative z-10 w-full flex-shrink-0 bottom-0 left-0 right-0 m-auto text-center flex justify-between items-center' style={{ paddingTop: '15px' }}>
            <div className='invisible'>
              <img src={SummitImageWhite} style={{ height: 30 }} />
            </div>
            <div className='invisible'>
              <img src={ContactlessIcon} style={{ height: 25 }} />
            </div>
          </div>
          <div className='absolute bottom-0 left-0 w-full z-0 flex justify-between mb-2'>
            <div className='w-1/3 flex justify-start'>
              <img src={PDX_2023_GFX1} style={{ height: 40 }} />
            </div>
            <div className='w-1/3 flex justify-center'>
              <img src={PDX_2023_LOGO} style={{ height: 40 }} />
            </div>
            <div className='w-1/3 flex justify-end'>
              <img src={PDX_2023_GFX2} style={{ height: 40 }} />
            </div>
          </div>
        </div>
        */}
        <props.badgeComponent
          name={props.name}
          badgeRef={badgeRef}
          linkRef={linkRef}
          upload={upload}
          avatar={avatar}
          renderImageSection={renderImageSection}
          nameRef={nameRef}
        />

      </div>
      <input ref={uploadRef} type="file" className='hidden' />
      <div className='flex flex-col sm:flex-row items-stretch sm:items-center mt-8 justify-center'>
        <div className='bg-gray-100 rounded p-2 m-2 cursor-pointer' onClick={props.goBack}>
          Go back
        </div>
        <div className='bg-gray-100 rounded p-2 m-2 cursor-pointer' onClick={upload}>
          Update Photo
        </div>
        <div className={`bg-gray-100 rounded p-2 m-2 cursor-pointer ${hasResolvedDomain && avatar ? '' : 'text-gray-300 pointer-events-none'}`} onClick={continueSteps}>
          Continue
        </div>
      </div>
    </div>
  )
}

function StepThree(props) {
  const [ hasFetchedLinks, setHasFetchedLinks ] = useState(false)
  const [ links, setLinks ] = useState(null)
  const [ editLink, setEditLink ] = useState(null)
  const editLinkTitle = useRef(null)
  const editLinkLink = useRef(null)

  useEffect(() => {
    let ignore = false
    const run = async () => {
      if (ignore || hasFetchedLinks) return
      let links
      if (props.links) {
        links = props.links.split(DELIMITER)
      } else {
        try {
          const data = await avvy.name(props.name).resolve('avax_sh_v1')
          links = data.split(DELIMITER)
        } catch (err) {
          links = []
        }
      }
      const results = []
      const editLinks = []
      for (let i = 0; i < links.length; i += 2) {
        if (i + 1 < links.length) {
          let title = links[i]
          let link = links[i+1]
          results.push({
            title,
            link
          })
        }
      }
      setLinks(results)
      setHasFetchedLinks(true)
    }
    run()
    return () => {
      ignore = true
    }
  })

  const moveLinkUp = (index) => {
    if (index === 0) return
    setLinks(ll => index === ll.length - 1 ? [
      ...ll.slice(0, index - 1),
      ll[index],
      ll[index - 1],
    ] : [
      ...ll.slice(0, index - 1),
      ll[index],
      ll[index - 1],
      ...ll.slice(index + 1)
    ])
  }

  const moveLinkDown = (index) => {
    if (index === links.length - 1) return
    setLinks(ll => {
      const nextLinks = index === 0 ? [
        ll[index + 1],
        ll[index],
        ...ll.slice(index + 2)
      ] : [
        ...ll.slice(0, index),
        ll[index + 1],
        ll[index],
        ...ll.slice(index + 2)
      ]
      return nextLinks
    })
  }

  const deleteLink = (index) => {
    setLinks(ll => {
      const nextLinks = ([
        ...ll.slice(0, index),
        ...ll.slice(index + 1)
      ])
      return nextLinks
    })
  }

  const saveEdit = () => {
    setLinks(ll => ll.map((link, index) => {
      if (index === editLink) {
        return {
          title: editLinkTitle.current.value,
          link: editLinkLink.current.value,
        }
      } else {
        return link
      }
    }))
    setEditLink(null)
  }

  const startEditLink = (index) => {
    editLinkTitle.current.value = links[index].title
    editLinkLink.current.value = links[index].link
    setEditLink(index)
  }

  const addLink = () => {
    const index = links.length
    setLinks(ll => [
      ...ll,
      {
        title: '',
        link: '',
      }
    ])
    editLinkTitle.current.value = ''
    editLinkLink.current.value = ''
    setEditLink(index)
  }

  const finalize = () => {
    const payload = links.reduce((sum, curr) => {
      sum += `${curr.title}${DELIMITER}${curr.link}${DELIMITER}`
      return sum
    }, '')
    props.completeStepThree(payload)
  }

  return (
    <div>
      <h1 className='font-bold text-4xl mb-4'>Design your Networking Page</h1>
      <div className='mb-4'>{"Here is what people will see when they scan your NFC badge 👇️"}</div>
      <div className='h-full relative w-auto inline-block'>
        <div className='absolute bg-white overflow-y-auto' style={{ left: '5%', top: '2.5%', width: '90%', height: '95%', borderRadius: '40px', padding: '20px 5px' }}>
          <div className='mt-4 aspect-square w-32 m-auto bg-gray-100 rounded bg-center bg-cover' style={{ borderRadius: '100%', backgroundImage: `url(${props.avatar})` }}></div>
          <div className='text-center mt-4 font-bold mb-8'>
            {props.name}
          </div>
          <div className={`${editLink === null ? 'hidden' : ''} absolute top-0 left-0 w-full h-full z-10`}>
            <div className='absolute z-10 flex items-center justify-center h-full w-full'>
              <div className='bg-white p-4 rounded' style={{ width: '90%' }}>
                <div className='flex-grow flex-col flex items-center justify-center'>
                  <div className='text-left w-full'>
                    <div className={`font-bold text-sm mr-4`}>Title</div>
                    <input ref={editLinkTitle} type="text" className='border border-gray-300 rounded text-sm w-full rounded px-2 py-1 w-full' />
                  </div>
                  <div className='mt-4 text-left w-full'>
                    <div className={`font-bold text-sm mr-4`}>Link</div>
                    <input ref={editLinkLink} type="text" className='border border-gray-300 rounded text-sm w-full rounded px-2 py-1 w-full' />
                  </div>
                </div>
                <div className='font-bold bg-black text-white rounded py-1 px-2 text-sm mt-4 cursor-pointer' onClick={() => saveEdit()}>
                  Save
                </div>
              </div>
            </div>
            <div className='absolute top-0 left-0 w-full h-full bg-black opacity-25'></div>
          </div>
          {links ? links.map((link, index) => (
            <div key={index} className='flex'>
              <div className='flex-col justify-around flex invisible pointer-events-none'>
                <div className={`cursor-pointer p-2`} onClick={() => moveLinkUp(index)}>
                  <ArrowUpIcon className={`w-4 text-gray-200`} />
                </div>
                <div className='cursor-pointer p-2' onClick={() => moveLinkDown(index)}>
                  <ArrowDownIcon className={`w-4 text-gray-200`} />
                </div>
                <div className='cursor-pointer p-2' onClick={() => deleteLink(index)}>
                  <TrashIcon className={`w-4 text-gray-200`} />
                </div>
              </div>
              <div className='flex-grow flex items-center justify-center border border-gray-300 rounded my-2 cursor-pointer' onClick={() => startEditLink(index)}>
                {link.title}
              </div>
              <div className='flex-col justify-around flex'>
                <div className={`${index === 0 ? 'text-gray-300 pointer-events-none' : ''} cursor-pointer p-2`} onClick={() => moveLinkUp(index)}>
                  <ArrowUpIcon className={`w-4`} />
                </div>
                <div className={`${index === links.length - 1 ? 'text-gray-300 pointer-events-none' : ''} cursor-pointer p-2`} onClick={() => moveLinkDown(index)}>
                  <ArrowDownIcon className={`w-4`} />
                </div>
                <div className='cursor-pointer p-2' onClick={() => deleteLink(index)}>
                  <TrashIcon className={`w-4`} />
                </div>
              </div>
            </div>
          )) : (
            <div><Loader style={{ color: '#999' }} /></div>
          )}
          {links && links.length === 0 ? (
            <div onClick={addLink}>{"Add some links to your social media pages, contact information, and anything else you might want to share by clicking the plus below 👇️"}</div>
          ) : null}
          <div className='p-4 border border-gray-300 inline-block m-auto mt-4 cursor-pointer' style={{ borderRadius: '50px' }} onClick={() => addLink()}>
            <PlusIcon className='w-4' /> 
          </div>
        </div>
        <img className='m-auto h-full' src={IPhoneImage} />
      </div>
      <div className='flex items-center mt-2 justify-center'>
        <div className='bg-gray-100 rounded p-2 m-2 cursor-pointer' onClick={props.goBack}>
          Go back
        </div>
        <div className='bg-gray-100 rounded p-2 m-2 cursor-pointer' onClick={finalize}>
          Continue
        </div>
      </div>
    </div>
  )
}

function StepFour(props) {
  const { data: signer, isError, isLoading } = useSigner()
  const { address } = useAccount()

  const name = props.name
  const avatar = props.avatar
  const links = props.links
  const avatarHasChanged = props.avatarHasChanged
  const message = 'I am proving ownership of ' + props.name + ' to claim my Avvy Domains Badge at ' + props.event.EVENT_NAME + '!'
  const [ avatarUpdateMessage, setAvatarUpdateMessage ] = useState(`I am proving ownership of ${props.name} to update my .avax domain avatar!\n\nRequested by: badge.avvy.domains\nNonce: ${parseInt(Date.now() / 1000)}`)
  const [ resolverState, setResolverState ] = useState(null)
  const [ needsUpdateAvatar, setNeedsUpdateAvatar ] = useState(false)
  const [ needsUpdateData, setNeedsUpdateData ] = useState(false)
  const [ isReady, setIsReady ] = useState(false)
  const [ currentStep, setCurrentStep ] = useState(0)
  const [ stepLoading, setStepLoading ] = useState(false)
  const [ signature, setSignature ] = useState(null)
  const [ avatarSignature, setAvatarSignature ] = useState(null)

  useEffect(() => {
    let ignore = false
      
    const run = async () => {
      if (ignore || isReady) return
      const contracts = await avvy.contracts
      const hash = await avvy.utils.nameHash(name)

      await Promise.all([

        /*
            1. Let's check the resolver to see if it is correctly set.
        */
        new Promise(async (resolve) => {
          try {
            const resolver = await contracts.ResolverRegistryV1.get(hash, hash)
            if (resolver[0] === contracts.PublicResolverV1.address) {
              setResolverState('has_resolver')
            } else {
              setResolverState('custom_resolver')
            }
          } catch (err) {
            setResolverState('no_resolver')
          }
          resolve()
        }),

        /*
            2. Let's check if the networking page data needs to be updated.
        */
        new Promise(async (resolve) => {
          try {
            const currData = await avvy.name(name).resolve('avax_sh_v1')
            if (currData !== links) {
              setNeedsUpdateData(true)
            }
          } catch (err) {
            setNeedsUpdateData(true)
          }
          resolve()
        })
      ])
      setIsReady(true)
    }
    run()

    return () => {
      ignore = true
    }
  })

  const updateAvatar = async () => {
    setStepLoading(true)
    
    // first lets upload the avatar
    const url = API_BASE_URL + '/api/' + name + '/avatar/'
    const res = await fetch(url, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ avatar, signature: avatarSignature, address, message: avatarUpdateMessage })
    })
    const data = await res.json()
    if (!data.success) {
      setStepLoading(false)
      return toast("Failed to set image")
    }

    const hash = await avvy.utils.nameHash(name)
    const contracts = await avvy.contracts
    try {
      const tx = await contracts.PublicResolverV1.connect(signer).setStandard(hash, [], avvy.RECORDS.AVATAR, data.url)
      await tx.wait()
      setCurrentStep(step => step + 1)
      setStepLoading(false)
    } catch (err) {
      console.log(err)
      toast('Failed to update avatar')
      setStepLoading(false)
    }
  }

  const saveNetworkingData = async () => {
    setStepLoading(true)
    const hash = await avvy.utils.nameHash(name)
    const contracts = await avvy.contracts
    try {
      const tx = await contracts.PublicResolverV1.connect(signer).set(hash, [], 'avax_sh_v1', links)
      await tx.wait()
      setCurrentStep(step => step + 1)
    } catch (err) {
      console.log(err)
      toast("Saving data failed")
    }
    setStepLoading(false)
  }
  
  const signOwnershipMessage = async () => {
    setStepLoading(true)
    try {
      const signature = await signer.signMessage(message)
      setSignature(signature)
      setCurrentStep(step => step + 1)
      setStepLoading(false)
    } catch (err) {
      console.log(err)
      toast("Signature failed")
      setStepLoading(false)
    }
  }

  const signAvatarMessage = async () => {
    setStepLoading(true)
    try {
      const signature = await signer.signMessage(avatarUpdateMessage)
      setAvatarSignature(signature)
      setCurrentStep(step => step + 1)
      setStepLoading(false)
    } catch (err) {
      console.log(err)
      toast("Signature failed")
      setStepLoading(false)
    }
  }

  const setDefaultResolver = async () => {
    const hash = await avvy.utils.nameHash(name)
    const contracts = await avvy.contracts
    setStepLoading(true)
    try {
      const tx = await contracts.ResolverRegistryV1.connect(signer).set(hash, [], contracts.PublicResolverV1.address, hash)
      await tx.wait()
      setCurrentStep(step => step + 1)
      setStepLoading(false)
    } catch (err) {
      toast("Failed to set resolver")
      setStepLoading(false)
    }
  }

  const steps = []
  if (props.badgeClaim) {
    steps.push({
      title: 'Sign badge claim message',
      action: signOwnershipMessage
    })
  }
  if (resolverState === 'no_resolver') {
    steps.push({
      title: 'Set your resolver',
      action: setDefaultResolver
    })
  }
  if (avatarHasChanged) {
    steps.push({
      title: 'Sign avatar update message',
      action: signAvatarMessage,
    })
    steps.push({
      title: 'Set your avatar',
      action: updateAvatar,
    })
  } 
  if (needsUpdateData) {
    steps.push({
      title: 'Save your networking page data',
      action: saveNetworkingData,
    })
  }

  return (
    <div className='max-w-sm m-auto'>
      <h1 className='font-bold text-4xl mb-4'>Save your data</h1>
      <div className='mb-4'>{steps.length > 0 ? 'We need to save the changes you have made.' : 'No data needs to be saved.'}</div>
      {isReady ? (
        <>
          {steps.map((step, index) => (
            <div key={index} className={`${currentStep >= index ? `text-black border-black` : 'text-gray-300 border-gray-300 pointer-events-none'} ${currentStep === index && stepLoading ? 'pointer-events-none' : ''} p-4 border border-black rounded my-2 cursor-pointer text-left relative`} onClick={step.action}>
              {currentStep === index && stepLoading ? (
                <div className='absolute right-0 top-0 h-full flex items-center justify-center' style={{ transform: 'scale(0.5)' }}>
                  <Loader />
                </div>
              ) : null}
              {currentStep > index ? (
                <div className='absolute right-0 top-0 h-full flex items-center justify-center' style={{ transform: 'scale(0.5)' }}>
                  <CheckIcon className='w-12' />
                </div>
              ) : null}
              {index + 1}. {step.title}
            </div>
          ))}
        </>
      ) : (
        <div><Loader /></div>
      )}
      <div className='flex items-center mt-2 justify-center'>
        <div className='bg-gray-100 rounded p-2 m-2 cursor-pointer' onClick={props.goBack}>
          Go back
        </div>
        <div className={`${currentStep === steps.length ? '' : 'text-gray-300 pointer-events-none'} bg-gray-100 rounded p-2 m-2 cursor-pointer`} onClick={() => props.completeStepFour(signature)}>
          Continue
        </div>
      </div>
    </div>
  )
}

function StepFive(props) {
  const twitterRef = useRef(null)

  function print() {
    window.print()
  }

  function downloadBadge() {
    const url = props.card
    const link = document.createElement('a')
    link.download = 'badge.png'
    link.href = url
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  function download() {
    const url = props.qrData
    const link = document.createElement('a')
    link.download = 'avvy-avalanche-summit-qr-code.png'
    link.href = url
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  async function downloadTwitterShare() {
    const canvas = await html2canvas(twitterRef.current, {
      scale: 6
    })
    const url = canvas.toDataURL()
    const link = document.createElement('a')
    link.download = 'twitter-badge.png'
    link.href = url
    document.body.appendChild(link)
    link.click()
    link.remove()
    
  }

  return (
    <div className='max-w-sm m-auto'>
      <h1 className='font-bold text-4xl mb-4 text-left'>Download QR Code</h1>
      <div className='mb-4 text-left'>{'Show us this QR code at Summit II to claim your badge!'}</div>
      <div className='mb-4 text-left'>{'We have a limited supply of badges available and badges will be printed on a first-come-first serve basis. Visit us early to secure yours!'}</div>
      <img className='w-full' src={props.qrData} />
      <div className='text-left text-red-500'>{"Warning: anyone who shows us this QR code can claim your badge. Do not share this with anyone except the Avvy team"}</div>
      <div className='flex items-center mt-2 justify-center'>
        <div className='bg-gray-100 rounded p-2 m-2 cursor-pointer' onClick={print}>
          Print
        </div>
        <div className='bg-gray-100 rounded p-2 m-2 cursor-pointer' onClick={download}>
          Download
        </div>
      </div>
      <div className='my-8 w-full bg-gray-500' style={{ height: 1 }} />
      <h1 className='text-left font-bold text-4xl mb-4'>Share your Badge Design</h1>
      <div className='mb-4 text-left'>{'Proud of your badge design? Download the image & show it off on social media. Tag us @avvydomains'}</div>
      <div className='relative' ref={twitterRef}>
        <div className='absolute h-full w-full flex items-center justify-center py-2'>
          <div className='rounded overflow-hidden h-full border border-black'>
            <img src={props.card} className='h-full m-auto' />
          </div>
        </div>
        <img src={props.event.SHARE_BG} />
      </div>
      <div className='flex flex-col items-stretch mt-2 justify-center'>
        <div className='bg-gray-100 rounded p-2 m-2 cursor-pointer' onClick={downloadBadge}>
          Download Badge
        </div>
        <div className='bg-gray-100 rounded p-2 m-2 cursor-pointer' onClick={downloadTwitterShare}>
          {'Download Image for Twitter'}
        </div>
      </div>
    </div>
  )
}

function Step(props) {
  return (
    <div className={`text-center mx-2 text-xs ${props.currStep >= props.step ? 'font-bold' : ''} font-bold ${props.currStep < props.step || props.currStep > props.step ? 'opacity-50': ''} `}>
      {props.step}. {props.title}
    </div>
  )
}

function Wizard() {
  const { address, isConnected, isDisconnected} = useAccount()
  const [step, setStep] = useState(1)
  const [name, setName] = useState(null)
  const [card, setCard] = useState(null)
  const [avatar, setAvatar] = useState(null)
  const [badgeEvent, setBadgeEvent] = useState(null)
  const [avatarRecord, setAvatarRecord] = useState(null)
  const [avatarHasChanged, setAvatarHasChanged] = useState(null)
  const [avatarData, setAvatarData] = useState(null)
  const [signature, setSignature] = useState(null)
  const [links, setLinks] = useState(null)
  const [qrData, setQRData] = useState(null)
  const [prevAddress, setPrevAddress] = useState(null)

  function completeStepOne(name) {
    setName(name)
    setStep(2)
    setLinks(null)
    setAvatar(null)
  }

  function completeStepTwo(avatarHasChanged, avatarData, avatarRecord, avatar, card) {
    setAvatarHasChanged(avatarHasChanged)
    setAvatarData(avatarData)
    setAvatarRecord(avatarRecord)
    setAvatar(avatar)
    setCard(card)
    setStep(3)
  }

  function completeStepThree(links) {
    setLinks(links)
    setStep(4)
  }

  async function completeStepFour(signature) {
    setSignature(signature)
    setStep(5)
    const payload = {
      signature,
      address,
      name
    }
    const url = btoa(JSON.stringify(payload))
    const qrData = await qrcode.toDataURL(url)
    setQRData(qrData)
  }

  if (address != prevAddress) {
    setPrevAddress(address)
    if (step !== 1) {
      setStep(1)
    }
  }

  if (!badgeEvent) {
    return (
      <div className='flex flex-col items-center bg-white sm:rounded-lg sm:shadow sm:border sm:border-gray-300 max-w-md m-auto px-4 sm:px-8 sm:p-8'>
        <div className='font-bold text-3xl mb-4'>{"Badge Setup"}</div>
        <div className='mb-6'>{"Which event are you attending?"}</div>
        <div className='w-full'>
          {Object.keys(EVENTS).map((eventKey, index) => (
            <div key={index} className='mb-4 text-center cursor-pointer border border-gray-300 w-full rounded p-4' onClick={() => setBadgeEvent(EVENTS[eventKey])}>
              {EVENTS[eventKey].EVENT_NAME}
            </div>
          ))}
        </div>
      </div>
    )
  }
  if (isConnected) return (
    <>
      <div className='flex items-center justify-center mb-8 flex-col sm:flex-row'>
        <Step currStep={step} step={1} title="Choose domain" />
        <Step currStep={step} step={2} title="Design badge" />
        <Step currStep={step} step={3} title="Configure links" />
        <Step currStep={step} step={4} title="Save data" />
        <Step currStep={step} step={5} title="Download QR Code" />
      </div>
      <div className='bg-white rounded p-8 max-w-screen-md m-auto text-center shadow pt-0 sm:pt-8'>
        {step === 1 ? (
          <StepOne address={address} completeStepOne={(name) => completeStepOne(name)} />
        ) : step === 2 ? (
          <StepTwo name={name} goBack={() => setStep(1)} completeStepTwo={completeStepTwo} badgeComponent={badgeEvent.BADGE_COMPONENT} />
        ) : step === 3 ? (
          <StepThree name={name} avatar={avatar} links={links} goBack={() => setStep(2)} completeStepThree={completeStepThree} />
        ) : step === 4 ? (
          <StepFour event={badgeEvent} badgeClaim={true} name={name} avatarHasChanged={avatarHasChanged} avatarRecord={avatarRecord} avatar={avatar} links={links} goBack={() => setStep(3)} completeStepFour={completeStepFour} />
        ) : step === 5 ? (
          <StepFive event={badgeEvent} card={card} qrData={qrData} name={name} signature={signature} address={address} goBack={() => setStep(4)} />
        ) : null}
      </div>
      <div className='my-8 px-2'>
        <a href="https://discord.gg/mn5un9mUqq" className='block text-center text-gray-700'>{'Have questions? Get support on our Discord! 🔗'}</a>
      </div>
    </>
  )
  return <ConnectWallet />
}

function ConnectWallet() {
  const { address, isConnected } = useAccount()
  const [ width, setWidth ] = useState(100000)
  const { connect } = useConnect({
    connector: new InjectedConnector(),
  })
  useEffect(() => {
    function doSetWidth() {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', doSetWidth)
    return () => {
      window.removeEventListener('resize', doSetWidth)
    }
  })
  return (
    <div className='flex flex-col items-center bg-white sm:rounded-lg sm:shadow sm:border sm:border-gray-300 max-w-md m-auto px-4 sm:px-8 sm:p-8'>
      <div className='font-bold text-3xl mb-4'>{"Badge Setup"}</div>
	  {/*<div className='max-w-screen-md text-left mb-8 sm:px-8'>{`Avvy Domains will be printing physical ID cards (\"badges\") for .avax domains at the ${ENV.EVENT_NAME}. These NFC tap-enabled badges are fun, customizable memorabilia & networking tools. These badges are NOT admission tickets to the ${ENV.EVENT_NAME}.`}</div>*/}
      <div className='mb-6'>{"Let's get your badge set up!"}</div>
      <AvvyConnectButton />
    </div>
  )
}

function VerifyBadge(props) {
  const [ hasResolvedDomain, setHasResolvedDomain ] = useState(false)
  const [ avatar, setAvatar ] = useState(null)
  const [ avatarURL, setAvatarURL ] = useState(null)
  const uploadRef = useRef(null)
  const badgeRef = useRef(null)
  const linkRef = useRef(null)
  const nameRef = useRef(null)

  useEffect(() => {
    let ignore = false
    const run = async () => {
      if (hasResolvedDomain || ignore) return
      try {
        const urlRaw = await avvy.name(props.name).resolve(avvy.RECORDS.AVATAR)
        if (urlRaw) {
          let url = ensAvatarUtils.resolveURI(urlRaw, {
            ipfs: 'https://w3s.link'
          }).uri
          const data = await fetch(url)
          const blob = await data.blob()
          const avatar = await new Promise((resolve) => {
            const reader = new FileReader()
            reader.readAsDataURL(blob)
            reader.onloadend = () => {
              const base64data = reader.result
              resolve(base64data)
            }
          })
          setAvatarURL(url)
          setAvatar(avatar)
        }
      } catch (err) {
      }
      setHasResolvedDomain(true)
    }
    run()
    return () => {
      ignore = true
    }
  })

  const renderImageSection = () => {
    if (!hasResolvedDomain) return <Loader />
    return null
  }

  const badgeImage = async () => {
    nameRef.current.style.transform = 'translateY(-50%)'
    linkRef.current.style.transform = 'translateY(-50%)'
    badgeRef.current.classList.remove('border-8')
    badgeRef.current.classList.remove('border', 'rounded-xl', 'shadow-lg')
    html2canvas(badgeRef.current, {
      scale: 4
    }).then((canvas) => {
      const url = canvas.toDataURL()
      const link = document.createElement('a')
      link.download = 'test.png'
      link.href = url
      document.body.appendChild(link)
      link.click()
      link.remove()
      nameRef.current.style.transform = ''
      linkRef.current.style.transform = ''
      //badgeRef.current.classList.add('border-8')
      badgeRef.current.classList.add('border', 'rounded-xl', 'shadow-lg')
    })
  }

  const copyNFCUrl = async () => {
    const url = 'https://badge.avvy.domains/' + props.name
    await navigator.clipboard.writeText(url)
    toast('String copied!')
  }

  let fontSize = 'text-4xl'
  let len = props.name.length
  if (len >= 21) {
    fontSize = 'text-xl'
  } else if (len >= 18) {
    fontSize = 'text-2xl'
  } else if (len >= 13) {
    fontSize = 'text-3xl'
  }

  const upload = () => {
    const onChange = (e) => {
      uploadRef.current.removeEventListener('change', onChange)
      const file = e.target.files[0]
      if (!file) return
      if (file.size / 1024 / 1024 > 2) {
        return toast('Image must be smaller than 2 megabytes')
      }
      const allowedTypes = [
        'image/png',
        'image/jpeg',
        'image/gif', 
      ]
      if (allowedTypes.indexOf(file.type) === -1) {
        return toast('Invalid file. JPG, PNG or GIF only!')
      }

      const reader = new FileReader()
      reader.onload = (e) => {
        setAvatar(e.target.result)
        setAvatarURL(e.target.result)
      }

      reader.readAsDataURL(file)
    }
    uploadRef.current.addEventListener('change', onChange)
    uploadRef.current.click()
  }

  const eventSlug = props.eventSlug 
  const EVENT = EVENTS[eventSlug]

  return (
    <div>
      <EventVerifyBadge name={props.name} badgeComponent={EVENT.BADGE_COMPONENT} eventSlug={eventSlug} />
    </div>
  )
}

function Verify() {
  const ref = useRef(null)
  const split = window.location.pathname.split('/')
  const eventSlug = split[split.length - 1]
  const [ hasInitialized, setHasInitialized ] = useState(false)
  const [ isScanning, setIsScanning] = useState(false)
  const [ name, setName ] = useState(null)
  const [ address, setAddress ] = useState(null)
  const [ ownerAddress, setOwnerAddress] = useState(null)
  const [ sigValid, setSigValid ] = useState(null)
  const loadNameRef = useRef(null)

  const initScanner = async () => {
    setIsScanning(true)

    if (navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: {
          deviceId: 'ee086f1727bd278c96d34fb406375233fa2bb631da1039ccc861fe3fff300cea'
        }})
        ref.current.srcObject = stream;
        const scanner = new QrScanner(ref.current, async (result) => {
          const audio = new Audio(Beep)
          audio.play()
          toast('Scanned!')
          scanner.destroy()
          setIsScanning(false)
          const { signature, name, address } = JSON.parse(atob(result.data))

          // verify signature
          const message = `I am proving ownership of ${name} to claim my Avvy Domains Badge at the ${ENV.EVENT_NAME} in ${ENV.EVENT_CITY}!`
          const recoveredAddress = ethers.utils.verifyMessage(message, signature)
          setSigValid(recoveredAddress === address)

          // verify owner of name is address
          const contracts = await avvy.contracts
          const hash = await avvy.utils.nameHash(name)
          const owner = await contracts.Domain.ownerOf(hash)
          setOwnerAddress(owner)

          setName(name)
          setAddress(address)

        }, {
          highlightScanRegion: true,
        })
        scanner.start()
        toast('Scanner Initialized')
      } catch (err) {
        console.log(err)
        toast('Something went wrong, check console')
      }
    }
  }

  const loadName = async () => {
    const name = loadNameRef.current.value
    setSigValid(false)

    // verify owner of name is address
    const contracts = await avvy.contracts
    const hash = await avvy.utils.nameHash(name)
    const owner = await contracts.Domain.ownerOf(hash)
    setOwnerAddress(owner)
    setName(name)
    setAddress('Manually loaded')
  }

  return (
    <div className="max-w-md m-auto w-full">
      <video className={`${isScanning ? '' : 'hidden'}`} autoPlay ref={ref} />
      {isScanning ? null : (
        <>
          {name ? (
            <div className='mb-4'>
              <h1 className='text-3xl text-center'>{name}</h1>
              <table className='text-xs border-collapse w-full my-8'>
                <tr>
                  <td className='border border-gray-300 p-1 table-cell'>Owner address</td>
                  <td className='border border-gray-300 p-1 table-cell'>{ownerAddress}</td>
                </tr>
                <tr>
                  <td className='border border-gray-300 p-1 table-cell'>Signature address</td>
                  <td className='border border-gray-300 p-1 table-cell'>{address}</td>
                </tr>
                <tr>
                  <td className='border border-gray-300 p-1 table-cell'>Signature valid?</td>
                  <td className='border border-gray-300 p-1 table-cell'>{sigValid ? (<span className='text-green-500 font-bold flex items-center'>Valid <CheckCircleIcon className='text-green-500 h-4' /></span>) : (<span className='text-red-500 font-bold flex items-center'>Invalid <XCircleIcon className='h-4' /></span>)} </td>
                </tr>
                <tr>
                  <td className='border border-gray-300 p-1 table-cell'>Owner address matches sig?</td>
                  <td className='border border-gray-300 p-1 table-cell'>{ownerAddress === address ? (<span className='text-green-500 font-bold flex items-center'>Valid <CheckCircleIcon className='text-green-500 h-4' /></span>) : (<span className='text-red-500 font-bold flex items-center'>Invalid <XCircleIcon className='h-4' /></span>)}</td>
                </tr>
              </table>
              <VerifyBadge eventSlug={eventSlug} name={name} />
              <div className='w-full bg-black text-white text-center py-2 cursor-pointer mt-4 mb-16' onClick={() => setName(null)}>Go Back</div>
            </div>
          ) : (
            <>
              <input className='w-full mb-4 py-2 px-4 border border-gray-400' type="text" ref={loadNameRef} placeholder="Enter .avax name" />
              <div className='w-full bg-black text-white text-center py-2 cursor-pointer mb-4' onClick={loadName}>Load Name</div>
              <div className='w-full bg-black text-white text-center py-2 cursor-pointer mb-16' onClick={initScanner}>Scan QR Code</div>
            </>
          )}
        </>
      )}
    </div>
  )
}

function ViewProfile({ domain, onSave, warnUser, showOverlay }) {
  const [ hasLoaded, setHasLoaded ] = useState(false)
  const [ links, setLinks ] = useState(null)
  const [ avatar, setAvatar ] = useState(null)
  const [ editLink, setEditLink ] = useState(null)
  const [ isSaved, setIsSaved ] = useState(false)
  const editLinkTitle = useRef(null)
  const editLinkLink = useRef(null)

  useEffect(() => {
    setHasLoaded(false)
    setLinks(null)
    setAvatar(null)
  }, [domain])

  useEffect(() => {
    let ignore = false
    const run = async () => {
      if (ignore || hasLoaded) return
      let links
      let avatar
      await Promise.all([
        new Promise(async (resolve, reject) => {
          try {
            const urlRaw = await avvy.name(domain).resolve(avvy.RECORDS.AVATAR)
            avatar = ensAvatarUtils.resolveURI(urlRaw, {
              ipfs: 'https://w3s.link'
            }).uri
            resolve()
          } catch (err) {
            reject()
          }
        }),
        new Promise(async (resolve, reject) => {
          try {
            const data = await avvy.name(domain).resolve('avax_sh_v1')
            links = data.split(DELIMITER)
          } catch (err) {
            links = []
          }
          resolve()
        }),
        new Promise(async (resolve, reject) => {
          let saved = window.localStorage.getItem('saved')
          if (saved) {
            saved = JSON.parse(saved)
          } else {
            saved = []
          }
          if (saved.indexOf(domain) > -1) {
            setIsSaved(true)
          }
          resolve()
        })
      ])
      const results = []
      const editLinks = []
      for (let i = 0; i < links.length; i += 2) {
        if (i + 1 < links.length) {
          let title = links[i]
          let link = links[i+1]
          results.push({
            title,
            link
          })
        }
      }
      setLinks(results)
      setAvatar(avatar)
      setHasLoaded(true)
    }
    run()
    return () => {
      ignore = true
    }
  }, [domain, hasLoaded])

  useEffect(() => {
  }, [isSaved])

  const saveProfile = () => {
    let saved = window.localStorage.getItem('saved')
    if (saved) {
      saved = JSON.parse(saved)
    } else {
      saved = []
    }
    if (saved.indexOf(domain) === -1) {
      saved.push(domain)
    }
    window.localStorage.setItem('saved', JSON.stringify(saved))
    setIsSaved(true)
    toast('Profile saved to your collection!')
    if (onSave) onSave()
  }

  const removeProfile = () => {
    let saved = window.localStorage.getItem('saved')
    if (saved) {
      saved = JSON.parse(saved)
    } else {
      saved = []
    }
    const index = saved.indexOf(domain)
    if (index > -1) {
      saved.splice(index, 1)
    }
    window.localStorage.setItem('saved', JSON.stringify(saved))
    toast('Profile removed from your collection!')
    setIsSaved(false)
    if (onSave) onSave()
  }

  const onClickLink = (link) => {
    return (e) => {
      if (!link.startsWith('https://') && !link.startsWith('http://')) {
        e.preventDefault()
        e.stopPropagation()
        showOverlay(link)
      }
    }
  }

  return (
    <div>
      <div className='h-full relative w-auto max-w-sm m-auto rounded-xl'>
        <div className='bg-white overflow-y-auto p-8'>
          {hasLoaded ? (
            <>
              <div className='mt-4 aspect-square w-32 m-auto bg-gray-100 rounded bg-center bg-cover' style={{ borderRadius: '100%', backgroundImage: `url(${avatar})` }}></div>
              <div className='text-center mt-4 font-bold mb-4'>
                {domain}
              </div>
		  {/*
              <div className='flex items-center justify-center'>
                {isSaved ? (
                  <div className='rounded m-auto bg-black py-2 px-4 inline-block items-center justify-center text-white cursor-pointer text-sm mb-8' onClick={() => removeProfile()}>
                    <div className='flex items-center justify-center'>
                      <BookmarkOutlineIcon className='w-4 mr-2' />
                      Remove from Collection
                    </div>
                  </div>
                ) : (
                  <div className='rounded m-auto bg-black py-2 px-4 inline-block items-center justify-center text-white cursor-pointer text-sm mb-8' onClick={() => saveProfile()}>
                    <div className='flex items-center justify-center'>
                      <BookmarkOutlineIcon className='w-4 mr-2' />
                      Save to Collection
                    </div>
                  </div>
                )}
              </div>
	      */}
              {links ? links.map((link, index) => (
                <div key={index} className='flex h-24 px-4'>
                  <a href={link.link} onClick={onClickLink(link.link)} className='p-2 text-center block flex-grow flex items-center justify-center border border-gray-300 rounded my-2 cursor-pointer'>
                    {link.title}
                  </a>
                </div>
              )) : null}
            </>
          ) : (
            <div className='text-center m-auto'><Loader style={{ color: '#999' }} /></div>
          )}
        </div>
      </div>
    </div>
  )
}

function Collection(props) {
  const [name, setName] = useState(null)
  const [items, setItems] = useState([])
  const [avatars, setAvatars] = useState({})
  const [hasLoadedItems, setHasLoadedItems] = useState(false)
  const [isLoadingItems, setIsLoadingItems] = useState(false)

  const refreshItems = async (force) => {
    if (hasLoadedItems && !force) return
    setHasLoadedItems(true)
    setIsLoadingItems(true)
    let saved = window.localStorage.getItem('saved')
    if (saved) {
      saved = JSON.parse(saved)
    } else {
      saved = []
    }
    if (saved === []) {
      return setItems(saved)
    }
    const actions = []
    const missingNames = []
    saved.forEach((name) => {
      if (!avatars[name]) {
        missingNames.push(name)
        actions.push(new Promise(async (resolve, reject) => {
          const url = await avvy.name(name).resolve(avvy.RECORDS.AVATAR)
          if (url) {
            try {
              const data = await fetch(url)
              const blob = await data.blob()
              const reader = new FileReader()
              reader.readAsDataURL(blob)
              reader.onloadend = () => {
                const base64data = reader.result
                resolve(base64data)
              }
            } catch (err) {
              resolve(null)
            }
          }
        }))
      }
    })
    const results = await Promise.all(actions)
    setAvatars((currAvatars) => {
      const updates = {}
      for (let i = 0; i < results.length; i += 1) {
        updates[missingNames[i]] = results[i]
      }
      return Object.assign({}, currAvatars, updates)
    })
    setItems(saved)
    setIsLoadingItems(false)
  }

  useEffect(() => {
    setHasLoadedItems(true)
    setIsLoadingItems(true)
    refreshItems()
  }, [])
  
  return (
    <div className='text-center mt-8'>
      <div className='text-center font-bold mb-4 text-3xl'>Collection</div>

      <div className='flex flex-col bg-white rounded-xl sm:border sm:border-gray-300 sm:shadow max-w-md m-auto mb-8'>
        {isLoadingItems ? (
          <div className='p-4'><Loader /></div>
        ) : items.length === 0 ? (
          <div className='p-4'>{`Scan & save NFC badge cards of people you meet at ${ENV.EVENT_NAME} in ${ENV.EVENT_CITY}! Cards that you save will be added to your Collection.`}</div>
        ) : (
          <div className='overflow-x-auto flex flex-col w-full relative pt-8' style={{ transformOrigin: 'top', transform: `scale(0.75)` }}>
            {items.map((s, i) => (
              <div key={i} className='flex-shrink-0 mb-8 cursor-pointer' onClick={() => setName(s)}>
                <CollectionBadge name={s} avatar={avatars[s]} />
              </div>
            ))}
          </div>
        )}
        <div className='fixed right-0 top-0 transition-all h-full w-full max-w-sm bg-white shadow-lg border border-gray-100 flex-col flex items-stretch justify-center' style={name === null ? { transform: 'translateX(100%)' } : {transform: 'translateX(0)'}} >
          <div className='text-black absolute top-0 right-0 p-4 cursor-pointer mt-20 bg-white z-10' style={{ borderRadius: '100px' }} onClick={() => setName(null)}>
            <XMarkIcon className='w-10' />
          </div>
          <div className='overflow-y-auto relative z-0'>
            <div className='w-full h-24' />
            <ViewProfile 
              domain={name} 
              onSave={() => refreshItems(true)} 
              warnUser={props.warnUser}
              showOverlay={props.showOverlay}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function CollectionBadge({ avatar, name }) {
  let fontSize = 'text-4xl'
  let len = name.length
  if (len >= 21) {
    fontSize = 'text-xl'
  } else if (len >= 18) {
    fontSize = 'text-2xl'
  } else if (len >= 13) {
    fontSize = 'text-3xl'
  }

  return (
    <div>
      <div className='relative w-full max-w-sm m-auto border-8 border-gray-300 rounded p-4 relative overflow-hidden flex flex-col' style={{ aspectRatio: '0.6296296296296297' }}>
        <div className='relative z-10'>
          <div className='flex justify-between items-center mb-4'>
            <div className='h-6'>
              <AvvySvg width="auto" height="25" />
            </div>
            <div className='text-xs font-bold'>
              {'avvy.domains'}
            </div>
          </div>
          <div className='top-0 left-0 right-0 m-auto absolute bg-gray-300' style={{ width: '100px', height: '24px', borderRadius: '20px', }}></div>
        </div>
        <div className='relative z-20 flex-shrink-0 aspect-square w-full bg-gray-100 bg-center bg-cover flex items-center justify-center'>
          <div className='relative z-10 bg-gray-100 bg-center bg-cover items-center justify-center flex cursor-pointer w-full h-full' style={{ borderRadius: '20px', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', backgroundImage: `url(${avatar})` }}></div>
          <div className='absolute top-0 h-full w-full bg-white z-0'></div>
        </div>
        <div className={`bg-white relative z-10 font-bold text-center ${fontSize} h-full flex items-center justify-center`} style={{ borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', }}>
          <div>
            {name}
          </div>
        </div>
        <div className='relative z-10 w-full flex-shrink-0 bottom-0 left-0 right-0 m-auto text-center flex justify-between items-center' style={{ paddingTop: '15px' }}>
          <div>
            <img src={SummitImageWhite} style={{ height: 30 }} />
          </div>
          <div>
            <img src={ContactlessIcon} style={{ height: 25 }} />
          </div>
        </div>
        <div className='absolute bottom-0 left-0 w-full z-0'>
          <svg width="100%" height="auto" viewBox="0 0 374 381" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M373 132L0.5 1V380H373V132Z" fill="black" stroke="black"/>
          </svg>
        </div>
      </div>
    </div>
  )
}

function Welcome(props) {
  return (
    <div className='bg-white sm:border sm:border-gray-300 rounded sm:shadow max-w-md m-auto w-full text-center p-4 py-8'>
      <h1 className='text-4xl font-bold' >Welcome!</h1>
      <div className='mt-4'>
        <div className='flex flex-col items-center justify-center max-w-sm m-auto'>
          <div className='my-2 mb-8 border border-gray-300 rounded w-full py-6 cursor-pointer' onClick={() => props.loadPage('setup')}>
            Setup Badge
          </div>
	  {/*
          <div className='my-2 cursor-pointer mb-8 border border-gray-300 rounded w-full py-6' onClick={() => props.loadPage('collection')}>
            View your Collection
          </div>
	  */}
          <AvvyConnectButton />
        </div>
      </div>
    </div>
  )
}

function EventVerifyBadge(props) {
  const [ hasResolvedDomain, setHasResolvedDomain ] = useState(false)
  const [ avatar, setAvatar ] = useState(null)
  const [ avatarURL, setAvatarURL ] = useState(null)
  const uploadRef = useRef(null)
  const badgeRef = useRef(null)
  const linkRef = useRef(null)
  const nameRef = useRef(null)

  useEffect(() => {
    let ignore = false
    const run = async () => {
      if (hasResolvedDomain || ignore) return
      try {
        const urlRaw = await avvy.name(props.name).resolve(avvy.RECORDS.AVATAR)
        if (urlRaw) {
          let url = ensAvatarUtils.resolveURI(urlRaw, {
            ipfs: 'https://w3s.link'
          }).uri
          const data = await fetch(url)
          const blob = await data.blob()
          const avatar = await new Promise((resolve) => {
            const reader = new FileReader()
            reader.readAsDataURL(blob)
            reader.onloadend = () => {
              const base64data = reader.result
              resolve(base64data)
            }
          })
          setAvatarURL(url)
          setAvatar(avatar)
        }
      } catch (err) {
      }
      setHasResolvedDomain(true)
    }
    run()
    return () => {
      ignore = true
    }
  })

  const renderImageSection = () => {
    if (!hasResolvedDomain) return <Loader />
    return null
  }

  const badgeImage = async () => {
    nameRef.current.style.transform = 'translateY(-50%)'
    linkRef.current.style.transform = 'translateY(-50%)'
    badgeRef.current.classList.remove('border-8')
    badgeRef.current.classList.remove('border', 'rounded-xl', 'shadow-lg')
    html2canvas(badgeRef.current, {
      scale: 4
    }).then((canvas) => {
      const url = canvas.toDataURL()
      const link = document.createElement('a')
      link.download = props.name + '.png'
      link.href = url
      document.body.appendChild(link)
      link.click()
      link.remove()
      nameRef.current.style.transform = ''
      linkRef.current.style.transform = ''
      //badgeRef.current.classList.add('border-8')
      badgeRef.current.classList.add('border', 'rounded-xl', 'shadow-lg')
    })
  }

  const copyNFCUrl = async () => {
    const url = 'https://badge.avvy.domains/' + props.name + '?event=' + props.eventSlug
    await navigator.clipboard.writeText(url)
    toast('String copied!')
  }

  let fontSize = 'text-4xl'
  let len = props.name.length
  if (len >= 21) {
    fontSize = 'text-xl'
  } else if (len >= 18) {
    fontSize = 'text-2xl'
  } else if (len >= 13) {
    fontSize = 'text-3xl'
  }

  const upload = () => {
    const onChange = (e) => {
      uploadRef.current.removeEventListener('change', onChange)
      const file = e.target.files[0]
      if (!file) return
      if (file.size / 1024 / 1024 > 2) {
        return toast('Image must be smaller than 2 megabytes')
      }
      const allowedTypes = [
        'image/png',
        'image/jpeg',
        'image/gif', 
      ]
      if (allowedTypes.indexOf(file.type) === -1) {
        return toast('Invalid file. JPG, PNG or GIF only!')
      }

      const reader = new FileReader()
      reader.onload = (e) => {
        setAvatar(e.target.result)
        setAvatarURL(e.target.result)
      }

      reader.readAsDataURL(file)
    }
    uploadRef.current.addEventListener('change', onChange)
    uploadRef.current.click()
  }

  return (
    <div>

      {/* SUMMIT II 2023 
      <div ref={badgeRef} className='relative w-full max-w-sm m-auto border-8 border-gray-300 rounded p-4 relative overflow-hidden flex flex-col' style={{ aspectRatio: '0.6296296296296297' }}>
        <div className='relative z-10'>
          <div className='flex justify-between items-center mb-4'>
            <div className='h-6'>
              <AvvySvg width="auto" height="25" />
            </div>
            <div className='text-xs font-bold' ref={linkRef}>
              {'avvy.domains'}
            </div>
          </div>
          <div className='top-0 left-0 right-0 m-auto absolute bg-gray-300' style={{ width: '100px', height: '24px', borderRadius: '20px', }}></div>
        </div>
        <div className='relative z-20 flex-shrink-0 aspect-square w-full bg-gray-100 bg-center bg-cover flex items-center justify-center'>
          <div onClick={upload} className='relative z-10 bg-gray-100 bg-center bg-cover items-center justify-center flex cursor-pointer w-full h-full' style={{ borderRadius: '20px', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', backgroundImage: `url(${avatar})` }}>{renderImageSection()}</div>
          <div className='absolute top-0 h-full w-full bg-white z-0'></div>
        </div>
        <div className={`bg-white relative z-10 font-bold text-center ${fontSize} h-full flex items-center justify-center`} style={{ borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', }}>
          <div ref={nameRef}>
            {props.name}
          </div>
        </div>
        <div className='relative z-10 w-full flex-shrink-0 bottom-0 left-0 right-0 m-auto text-center flex justify-between items-center' style={{ paddingTop: '15px' }}>
          <div>
            <img src={SummitImageWhite} style={{ height: 30 }} />
          </div>
          <div>
            <img src={ContactlessIcon} style={{ height: 25 }} />
          </div>
        </div>
        <div className='absolute bottom-0 left-0 w-full z-0'>
          <svg width="100%" height="auto" viewBox="0 0 374 381" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M373 132L0.5 1V380H373V132Z" fill="black" stroke="black"/>
          </svg>
        </div>
      </div>
      */}

      {/*<div ref={badgeRef} className='z-0 relative w-full max-w-sm m-auto border border-black shadow-lg rounded-xl p-4 relative overflow-hidden flex flex-col' style={{ aspectRatio: '0.6296296296296297', width: '384px', backgroundColor: 'white' }}>
        <div className='relative z-10'>
          <div className='flex justify-between items-center mb-4'>
            <div className='h-6'>
              <AvvySvg width="auto" height="25" />
            </div>
            <div className='text-xs font-bold' ref={linkRef}>
              {'avvy.domains'}
            </div>
          </div>
          <div className='top-0 left-0 right-0 m-auto absolute bg-gray-300' style={{ width: '100px', height: '24px', borderRadius: '20px', }}></div>
        </div>
        <div className='relative z-20 flex-shrink-0 aspect-square w-full bg-gray-100 bg-center bg-cover flex items-center justify-center cursor-pointer'>
          <div className='relative z-10 bg-gray-100 bg-center bg-cover items-center justify-center flex cursor-pointer w-full h-full border-2 border-black' style={{ borderRadius: '20px 20px 0 0', backgroundImage: `url(${avatar})` }}>{renderImageSection()}</div>
          <div className='absolute top-0 h-full w-full bg-white z-0'></div>
        </div>
        <div className={`bg-white relative z-10 font-bold text-center ${fontSize} h-full flex items-center justify-center border-2 border-black border-t-0`} style={{ borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', }}>
          <div ref={nameRef} style={{ paddingLeft: '10px', paddingRight: '10px', width: '100%', overflowWrap: 'anywhere' }}>
            {props.name.replace('.', '\u200B.')}
          </div>
        </div>
        <div className='relative z-10 w-full flex-shrink-0 bottom-0 left-0 right-0 m-auto text-center flex justify-between items-center' style={{ paddingTop: '15px' }}>
          <div className='invisible'>
            <img src={SummitImageWhite} style={{ height: 30 }} />
          </div>
          <div className='invisible'>
            <img src={ContactlessIcon} style={{ height: 25 }} />
          </div>
        </div>
        <div className='absolute bottom-0 left-0 w-full z-0 flex justify-between mb-2'>
          <div className='w-1/3 flex justify-start'>
            <img src={PDX_2023_GFX1} style={{ height: 40 }} />
          </div>
          <div className='w-1/3 flex justify-center'>
            <img src={PDX_2023_LOGO} style={{ height: 40 }} />
          </div>
          <div className='w-1/3 flex justify-end'>
            <img src={PDX_2023_GFX2} style={{ height: 40 }} />
          </div>
        </div>
      </div>*/}

      <props.badgeComponent
        name={props.name}
        badgeRef={badgeRef}
        linkRef={linkRef}
        upload={upload}
        avatar={avatar}
        renderImageSection={renderImageSection}
        nameRef={nameRef}
      />
      <input ref={uploadRef} type="file" className='hidden' />
      <div className='flex items-center mt-8 justify-center'>
        <div className='bg-black text-white w-full py-2 text-center cursor-pointer' onClick={badgeImage}>
          Download image
        </div>
      </div>
      <div className='flex items-center mt-4 justify-center'>
        <div className='bg-black text-white w-full py-2 text-center cursor-pointer' onClick={copyNFCUrl}>
          Copy NFC URL
        </div>
      </div>
    </div>
  )
}


/* This is for on-site events, to make it easy to print out badge designs 
 * that were not made in advance. */
function EventWizard() {
  const split = window.location.pathname.split('/')
  const eventSlug = split[split.length - 1]
  const EVENT = EVENTS[eventSlug]
  const { address, isConnected, isDisconnected} = useAccount()
  const [step, setStep] = useState(1)
  const [name, setName] = useState(null)
  const [card, setCard] = useState(null)
  const [avatar, setAvatar] = useState(null)
  const [avatarRecord, setAvatarRecord] = useState(null)
  const [avatarHasChanged, setAvatarHasChanged] = useState(null)
  const [avatarData, setAvatarData] = useState(null)
  const [signature, setSignature] = useState(null)
  const [links, setLinks] = useState(null)
  const [qrData, setQRData] = useState(null)
  const [prevAddress, setPrevAddress] = useState(null)

  function completeStepOne(name) {
    setName(name)
    setStep(2)
    setLinks(null)
    setAvatar(null)
  }

  function completeStepTwo(avatarHasChanged, avatarData, avatarRecord, avatar, card) {
    setAvatarHasChanged(avatarHasChanged)
    setAvatarData(avatarData)
    setAvatarRecord(avatarRecord)
    setAvatar(avatar)
    setCard(card)
    setStep(3)
  }

  function completeStepThree(links) {
    setLinks(links)
    setStep(4)
  }

  async function completeStepFour(signature) {
    setSignature(signature)
    setStep(5)
    const payload = {
      signature,
      address,
      name
    }
    const url = btoa(JSON.stringify(payload))
    const qrData = await qrcode.toDataURL(url)
    setQRData(qrData)
  }

  if (address != prevAddress) {
    setPrevAddress(address)
    if (step !== 1) {
      setStep(1)
    }
  }

  if (isConnected) return (
    <>
      {EVENT ? (
        <>
          <div className='flex items-center justify-center mb-8 flex-col sm:flex-row'>
            <Step currStep={step} step={1} title="Choose domain" />
            <Step currStep={step} step={2} title="Design badge" />
            <Step currStep={step} step={3} title="Configure links" />
            <Step currStep={step} step={4} title="Save data" />
            <Step currStep={step} step={5} title="Print badge" />
          </div>
          <div className='bg-white rounded p-8 max-w-screen-md m-auto text-center shadow pt-0 sm:pt-8'>
            {step === 1 ? (
              <StepOne address={address} completeStepOne={(name) => completeStepOne(name)} />
            ) : step === 2 ? (
              <StepTwo name={name} goBack={() => setStep(1)} completeStepTwo={completeStepTwo} badgeComponent={EVENT.BADGE_COMPONENT} />
            ) : step === 3 ? (
              <StepThree name={name} avatar={avatar} links={links} goBack={() => setStep(2)} completeStepThree={completeStepThree} />
            ) : step === 4 ? (
              <StepFour event={EVENT} name={name} avatarHasChanged={avatarHasChanged} avatarRecord={avatarRecord} avatar={avatar} links={links} goBack={() => setStep(3)} completeStepFour={completeStepFour} badgeClaim={false} />
            ) : step === 5 ? (
              <div>
              {/*<StepFive card={card} qrData={qrData} name={name} signature={signature} address={address} goBack={() => setStep(4)} />*/}
                <EventVerifyBadge name={name} badgeComponent={EVENT.BADGE_COMPONENT} eventSlug={eventSlug} />
                <div className='flex flex-col sm:flex-row items-stretch sm:items-center mt-8 justify-center'>
                  <div className='bg-gray-100 rounded p-2 m-2 cursor-pointer' onClick={() => setStep(4)}>
                    Go back
                  </div>
                  <div className='bg-gray-100 rounded p-2 m-2 cursor-pointer' onClick={() => setStep(1)}>
                    Restart
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className='my-8 px-2'>
            <a href="https://discord.gg/mn5un9mUqq" className='block text-center text-gray-700'>{'Have questions? Get support on our Discord! 🔗'}</a>
          </div>
        </>
      ) : (
        <div className='text-center p-4'>{'Event not found. Contact Avvy Domains to get the correct link.'}</div>
      )}
    </>
  )
  return <ConnectWallet />
}

function App() {
  const { address, isConnected } = useAccount()
  const urlParams = new URLSearchParams(window.location.search)
  const [ page, setPage ] = useState(null)
  const [ pageArgs, setPageArgs ] = useState({})
  const [ menuOpen, setMenuOpen ] = useState(false)
  const [ showWarning, setShowWarning ] = useState(false)
  const [ overlay, setOverlay] = useState(null)

  const loadPage = (nextPage, nextPageArgs, skipPushState) => {
    if (!nextPageArgs) nextPageArgs = {}
    let url = '/-/' + nextPage
    if (nextPage === 'welcome') {
      url = '/'
    }
    if (!skipPushState) {
      window.history.pushState({}, null, url)
    }
    if (nextPage !== page || JSON.stringify(nextPageArgs) !== JSON.stringify(pageArgs)) {
      setPage(nextPage)
      setPageArgs(nextPageArgs)
    }
    setMenuOpen(false)
    document.documentElement.scrollTop = 0
  }

  function loadPageFromUrl() {
    const pathname = window.location.pathname
    if (pathname === '/') {
      return loadPage('welcome', {}, true)
    }
    const match = pathname.match(new RegExp(/\/-\/([a-z\-]+)/))
    if (match) {
      return loadPage(match[1], {}, true)
    }
    return loadPage('domain', {
      domain: pathname.replace('/', '')
    }, true)
  }

  function warnUser() {
    setShowWarning(true)
  }

  function showOverlay(info) {
    setOverlay(info)
  }

  function copyOverlay() {
    navigator.clipboard.writeText(overlay)
    toast('Copied to clipboard!')
  }

  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      loadPageFromUrl()
    })
    loadPageFromUrl()
    return () => {
    }
  }, [page, pageArgs])

  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains} theme={lightTheme({
        accentColor: 'black',
      })}>
        <QueryClientProvider client={queryClient}>
          <ToastContainer
          />
          <div className='h-24 w-full'></div>
          <div className='w-screen flex justify-center'>
          <div className='relative w-full'>
            {overlay ? (
              <div className='z-10 fixed top-0 right-0 w-full h-full'>
                <div className='h-24 w-full'></div>
                <div className='bg-black opacity-25 w-full h-full z-0 absolute top-0 left-0'></div>
                <div className='bg-white relative z-10 flex flex-col items-center justify-center max-w-sm m-auto p-8 rounded-lg'>
                  <div>
                    {overlay}
                  </div>
                  <div className='mt-4'>
                    <div className='cursor-pointer inline-block bg-black rounded-lg text-white py-2 px-4 mx-1' onClick={() => copyOverlay()}>
                      Copy
                    </div>
                    <div className='cursor-pointer inline-block bg-black rounded-lg text-white py-2 px-4 mx-1' onClick={() => setOverlay(null)}>
                      Close
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {showWarning ? (
              <div className='z-10 fixed top-0 right-0 w-full h-full'>
                <div className='h-24 w-full'></div>
                <div className='bg-black opacity-25 w-full h-full z-0 absolute top-0 left-0'></div>
                <div className='bg-white relative z-10 flex flex-col items-center justify-center max-w-sm m-auto p-8 rounded-lg'>

                  <h1 className='text-xl font-bold'>Important!</h1>
                  <div className='text-left mt-4'>
                    {'Your collection is stored in your web browser on your device. If your browser storage gets erased, you will lose your collection.'}
                  </div>
                  <div className='text-red-500 text-left mt-4'>
                    {'If you are using a Private Browsing mode, your device may automatically delete your Collection. It is recommended to not use Private Browsing mode.'}
                  </div>
                  <div className='mt-4'>
                    <div className='cursor-pointer inline-block bg-black rounded-lg text-white py-2 px-4' onClick={() => setShowWarning(false)}>
                      Continue
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <div className='z-10 fixed top-0 right-0 w-full h-full bg-white transition-all' style={menuOpen ? { transform: 'translateX(0)' } : { transform: 'translateX(100%)' }}>
              <div className='h-24 w-full'></div>
              <div className='flex flex-col items-center justify-center max-w-sm m-auto px-8'>
                <div className='my-2 mb-8 border border-gray-300 rounded w-full py-6 cursor-pointer text-center' onClick={() => loadPage('setup')}>
                  Setup Badge
                </div>
	  {/*
                <div className='my-2 cursor-pointer mb-8 border border-gray-300 rounded w-full py-6 text-center' onClick={() => loadPage('collection')}>
                  View your Collection
                </div>
		*/}
                <AvvyConnectButton />
              </div>
            </div>
            <div className='z-20 bg-white border-b border-gray-200 fixed top-0 left-0 w-full p-4'>
              <div className='flex justify-between items-stretch'>
                <div className='h-10 cursor-pointer' onClick={() => loadPage('welcome')}>
                  <AvvySvg width="auto" height="40" />
                </div>

                {/* Desktop menu */}
                <div className='hidden sm:flex items-center'>
                  <div className='mx-2 cursor-pointer mr-4' onClick={() => loadPage('setup')}>
                    Setup
                  </div>
	 {/*
                  <div className='mx-2 cursor-pointer mr-4' onClick={() => loadPage('collection')}>
                    Collection
                  </div>
		  */}
                  <AvvyConnectButton />
                </div>

                {/* Mobile menu */}
                <div className='flex sm:hidden items-center'>
                  <div className='cursor-pointer' onClick={() => setMenuOpen((menuOpen) => !menuOpen)}>
                    <Bars3Icon className='w-10' />
                  </div>
                </div>
              </div>
            </div>
            {page === 'verify' ? (
              <Verify /> 
            ) : page === 'collection' ? (
              <Collection warnUser={warnUser} showOverlay={showOverlay} />
            ) : page === 'setup' ? (
              <Wizard />
            ) : page === 'event' ? (
              <EventWizard />
            ) : page === 'welcome' ? (
              <Welcome loadPage={loadPage} />
            ) : (
              <div className='max-w-sm m-auto bg-white sm:border sm:border-gray-100 sm:shadow rounded-xl'>
                <ViewProfile 
                  domain={pageArgs.domain} 
                  warnUser={warnUser}
                  showOverlay={showOverlay}
                />
              </div>
            )}
          </div>
        </div>
        </QueryClientProvider>
        <Web3Modal 
          enableAccountView={false}
          projectId={projectId} 
          ethereumClient={ethereumClient} 
          themeVariables={{
            '--w3m-font-family': 'Lexend',
            '--w3m-accent-color': '#000000',
            '--w3m-background-color': '#222222',
            '--w3m-accent-fill-color': '#ffffff',
          }}
        />
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App
